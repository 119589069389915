import styled from 'styled-components';
import {alertColors, sizes} from '../../../../styles/variables';
import {withUnit} from '../../../../styles/utils';

export const InnerErrorMessageStyled = styled.p`
	padding-left: ${withUnit(sizes.distance.base)};
	text-align: left;
	color: ${alertColors.error.full};
	margin-bottom: 0;
	font-size: 0.8em;
`;
